import ApiService from "@/core/services/ApiService";

// 取得IoT點位指令
export function getDataPointCommandByDevice(deviceGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Device/${deviceGuid}/DataPointCommand`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增裝置點位指令
export function addDataPointCommand(deviceGuid,response) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Device/${deviceGuid}/DataPointCommand`,response).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 刪除點位裝置
export function deleteDataPointCommand(deviceGuid,dataPointCommandGuid) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Device/${deviceGuid}/DataPointCommand/${dataPointCommandGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}